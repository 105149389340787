import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import { useSettings } from '../hooks/use-settings'
import { Image } from '../components/image'
import SvgSmallUpRightArrow from '../generated/svg/small-up-right-arrow'
import { useBreakpoint } from '../hooks/use-break-point'
import { Animate } from '../components/animate'

const Outer = styled.div`
  padding: 8rem 0;
  @media (max-width: 40rem){
    padding-top: 4rem;
  }
`

const Inner = styled.div`
  max-width: var(--page-max-width);
  padding: 0 var(--page-margin);
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`

const Item = styled.a`
  display: flex;
  border: 1px solid var(--gold);
  background-color: var(--gold);
  color: var(--black);
  transform: translateY(-2rem);
  transition: translate 0.8s, opacity, 0.8s;
  opacity: 0;
  @media (max-width: 40rem){
    flex-direction: column;
    transform: none;
  }
  &:nth-child(1) {
    transition-delay: 0.2s;
  }
  &:nth-child(2) {
    transition-delay: 0.4s;
  }
  &:nth-child(3) {
    transition-delay: 0.6s;
  }
  .animate > & {
    opacity: 1;
    &:nth-child(1) {
      transform: none;
    }
    &:nth-child(2) {
      transform: translateY(4rem);
      @media (max-width: 40rem){
        transform: none;
      }
    }
  }
`

const ImageContainer = styled.div`
  img {
    display: block;
    height: 20rem;
    width: auto;
    @media (max-width: 40rem){
      height: auto;
      width: 100%;
    }
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  flex: 1 1 auto;
  @media (max-width: 40rem){
    padding: 1rem;
  }
`

const Title = styled.div`
  font-size: 2.625rem;
  font-family: forum, serif;
  @media (max-width: 40rem){
    font-size: 1.75rem;
  }
`

const slideIn = keyframes`
  from {
    transform: translate(-0.7071rem, 0.7071rem);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
`

const ViewMore = styled.div`
  margin-top: auto;
  text-align: right;
  font-size: 1.25rem;
  letter-spacing: 0.03em;
  font-family: forum, serif;
  @media (max-width: 40rem){
    font-size: 1rem;
    margin-top: 4rem;
  }
  svg {
    height: 0.75em;
    width: auto;
    margin-left: 1rem;
    .animate & {
      animation: ${slideIn} 1s 0.4s ease-in-out both;
    }
  }
  ${Item}:hover & svg{
    animation: ${slideIn} 1s 0.4s ease-in-out both, ${slideIn} 0.4s ease-in-out both;
  }
`

type TopPageBottomSectionProps = {

}
export const TopPageBottomSection: FunctionComponent<TopPageBottomSectionProps> = () => {
  const {themeFileRootPath} = useSettings()
  const isMobile = useBreakpoint('40rem')
  return <Outer>
    <Inner>
      <Animate>
        <Item href="/shop/products/KL-009-001">
          <ImageContainer>
            {
              isMobile ?
              <Image src={`${themeFileRootPath}/assets/bundle/images/top_0008m.jpg`} /> :
              <Image src={`${themeFileRootPath}/assets/bundle/images/top_0008.jpg`} />
            }
          </ImageContainer>
          <TextContainer>
            <Title>
              PRODUCTS
            </Title>
            <ViewMore>
              VIEW MORE
              <SvgSmallUpRightArrow />
            </ViewMore>
          </TextContainer>
        </Item>
        <Item href="/shop/faq">
          <ImageContainer>
            {
              isMobile ?
              <Image src={`${themeFileRootPath}/assets/bundle/images/top_0009m.jpg`} /> :
              <Image src={`${themeFileRootPath}/assets/bundle/images/top_0009.jpg`} />
            }
          </ImageContainer>
          <TextContainer>
            <Title>
              FAQ
            </Title>
            <ViewMore>
              VIEW MORE
                <SvgSmallUpRightArrow />
            </ViewMore>
          </TextContainer>
        </Item>
      </Animate>
    </Inner>
  </Outer>
}