import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import SvgSmallUpRightArrow from '../generated/svg/small-up-right-arrow'
import { useSettings } from '../hooks/use-settings'
import { Image } from '../components/image'

const Outer = styled.div`
  position: relative;
  padding: 8rem 0;
  overflow-x: hidden;
  @media (max-width: 40rem){
    padding-top: 6rem;
    padding-bottom: 2rem;
  }
`

const HeadOuter = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1;
  margin-top: -6rem;
  left: 0;
  @media (max-width: 40rem){
    position: static;
  }
`

const HeadInner = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: var(--page-max-width);
  padding: 0 var(--page-margin);
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 40rem){
    align-items: center;
  }
`

const Head = styled.div`
  font-size: 3rem;
  font-family: forum, serif;
  writing-mode: vertical-rl;
  color: white;
  @media (max-width: 40rem){
    font-size: 2rem;
    writing-mode: horizontal-tb;
  }
`

const slideIn = keyframes`
  from {
    transform: translate(-0.7071rem, 0.7071rem);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
`

const FollowUs = styled.a`
  display: block;
  font-size: 1.25rem;
  letter-spacing: 0.03em;
  font-family: forum, serif;
  color: white;
  margin-top: 2rem;
  @media (max-width: 40rem){
    font-size: 1rem;
    margin-top: 0;
  }
  svg {
    height: 0.75em;
    width: auto;
    margin-left: 1rem;
    .animate & {
      animation: ${slideIn} 1s 0.4s ease-in-out both;
    }
  }
  &:hover svg{
    animation: ${slideIn} 1s 0.4s ease-in-out both, ${slideIn} 0.4s ease-in-out both;
  }
`

const scroll = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
`
const ItemContainer = styled.div`
  display: flex;
  gap: 1.25rem;
  width: max-content;
  animation: ${scroll} 30s linear infinite;
  @media (max-width: 40rem){
    margin-top: 2rem;
  }
`

const Item = styled.a`
  flex: 0 0 auto;
  aspect-ratio: 1 / 1;
  display: block;
`

const ImageContainer = styled.div`
  overflow: hidden;
  img {
    display: block;
    height: 26.250rem;
    width: auto;
    transform: scale(1.01);
    @media (max-width: 60rem){
      height: 22rem;
    }
    @media (max-width: 50rem){
      height: 20rem;
    }
    @media (max-width: 40rem){
      height: 17.500rem;
    }
  }
`

type TopPageInstagramSectionProps = {

}
export const TopPageInstagramSection: FunctionComponent<TopPageInstagramSectionProps> = () => {
  const {themeFileRootPath} = useSettings()
  return <Outer>
    <HeadOuter>
      <HeadInner>
        <Head>
          INSTAGRAM
        </Head>
        <FollowUs href="https://www.instagram.com/kalonear_official/" target='_blank'>
          FOLLOW US
          <SvgSmallUpRightArrow />
        </FollowUs>
      </HeadInner>
    </HeadOuter>
    <ItemContainer>
      <Item href="https://www.instagram.com/kalonear_official/" target='_blank'>
        <ImageContainer>
          <Image src={`${themeFileRootPath}/assets/bundle/images/instagram_0001.jpg`} />
        </ImageContainer>
      </Item>
      <Item href="https://www.instagram.com/kalonear_official/" target='_blank'>
        <ImageContainer>
          <Image src={`${themeFileRootPath}/assets/bundle/images/instagram_0002.jpg`} />
        </ImageContainer>
      </Item>
      <Item href="https://www.instagram.com/kalonear_official/" target='_blank'>
        <ImageContainer>
          <Image src={`${themeFileRootPath}/assets/bundle/images/instagram_0003.jpg`} />
        </ImageContainer>
      </Item>
      <Item href="https://www.instagram.com/kalonear_official/" target='_blank'>
        <ImageContainer>
          <Image src={`${themeFileRootPath}/assets/bundle/images/instagram_0004.jpg`} />
        </ImageContainer>
      </Item>
      <Item href="https://www.instagram.com/kalonear_official/" target='_blank'>
        <ImageContainer>
          <Image src={`${themeFileRootPath}/assets/bundle/images/instagram_0001.jpg`} />
        </ImageContainer>
      </Item>
      <Item href="https://www.instagram.com/kalonear_official/" target='_blank'>
        <ImageContainer>
          <Image src={`${themeFileRootPath}/assets/bundle/images/instagram_0002.jpg`} />
        </ImageContainer>
      </Item>
      <Item href="https://www.instagram.com/kalonear_official/" target='_blank'>
        <ImageContainer>
          <Image src={`${themeFileRootPath}/assets/bundle/images/instagram_0003.jpg`} />
        </ImageContainer>
      </Item>
      <Item href="https://www.instagram.com/kalonear_official/" target='_blank'>
        <ImageContainer>
          <Image src={`${themeFileRootPath}/assets/bundle/images/instagram_0004.jpg`} />
        </ImageContainer>
      </Item>
    </ItemContainer>
  </Outer>
}