import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useSettings } from '../hooks/use-settings'
import SvgSmallUpRightArrow from '../generated/svg/small-up-right-arrow'
import { Animate } from '../components/animate'

const Outer = styled.div`
  max-width: var(--page-max-width);
  padding: 8rem var(--page-margin);
  margin: 0 auto;
  --gap: 5rem;
  @media (max-width: 70rem){
    --gap: 3rem;
  }
  @media (max-width: 60rem){
    --gap: 2rem;
  }
  @media (max-width: 40rem){
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
`

const Inner = styled.div`
  position: relative;
  display: flex;
  @media (max-width: 40rem){
    flex-direction: column;
  }
`

const Head = styled.div`
  font-size: 3rem;
  writing-mode: vertical-rl;
  font-family: forum, serif;
  @media (max-width: 40rem){
    writing-mode: horizontal-tb;
    font-size: 2rem;
  }
`

const ItemContainerWrapper = styled.div`
  @media (max-width: 40rem){
    overflow-x: scroll;
    overflow-y: visible;
    padding-bottom: calc(var(--gap) * 2 + 1rem);
  }
`

const ItemContainer = styled.div`
  margin-left: var(--gap);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap);
  @media (max-width: 40rem){
    margin-top: 2rem;
    margin-left: 0;
    width: min-content;
  }
`

const Item = styled.a`
  display: block;
  transform: translateY(-2rem);
  transition: translate 0.8s, opacity, 0.8s;
  opacity: 0;
  &:nth-child(1) {
    transition-delay: 0.2s;
  }
  &:nth-child(2) {
    transition-delay: 0.4s;
  }
  &:nth-child(3) {
    transition-delay: 0.6s;
  }
  .animate & {
    opacity: 1;
    &:nth-child(1){
      transform: none;
    }
    &:nth-child(2){
      transform: translateY(var(--gap));
    }
    &:nth-child(3){
      transform: translateY(calc(var(--gap) * 2));
    }
  }
`

const ItemImageContainer = styled.div`
  border: 1px solid var(--gold);
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.8s;
    @media (max-width: 40rem){
      width: 10rem;
    }
    ${Item}:hover & {
      transform: scale(1.05);
    }
  }
`

const ItemTitle = styled.div`
  font-size: 1rem;
  margin-top: 2.25rem;
  line-height: 1.7;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    margin-top: 1.75rem;
  }
`

const ItemText = styled.div`
  font-size: 0.8125rem;
  margin-top: 1rem;
  line-height: 1.7;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  @media (max-width: 40rem){
    font-size: 0.688rem;
    margin-top: 0.5rem;
  }
`

const ViewAll = styled.a`
  writing-mode: vertical-rl;
  font-size: 1.250rem;
  font-family: forum, serif;
  position: absolute;
  left: 0;
  bottom: 0;
  @media (max-width: 40rem){
    bottom: auto;
    top: 1rem;
    left: auto;
    right: 0;
    writing-mode: horizontal-tb;
    font-size: 1rem;
    vertical-align: baseline;
  }
  svg {
    display: none;
    @media (max-width: 40rem){
      display: inline;
      width: 0.75em;
      height: auto;
      margin-left: 0.5rem;
    }
  }
`


type TopPageTopicsSectionProps = {

}
export const TopPageTopicsSection: FunctionComponent<TopPageTopicsSectionProps> = () => {
  const {topics} = useSettings()
  if(!topics) return null
  return <Outer>
    <Inner>
      <Head>TOPICS</Head>
      <ItemContainerWrapper>
        <Animate>
          <ItemContainer>
            {topics.map((topic, i) => {
              return <Item key={i} href={`/shop/information/${topic.slug}`}>
                  <ItemImageContainer>
                    <img src={topic.img} />
                  </ItemImageContainer>
                  <ItemTitle>
                    {topic.title}
                  </ItemTitle>
                  <ItemText dangerouslySetInnerHTML={{__html: topic.content}} />
                </Item>
            })}
          </ItemContainer>
        </Animate>
      </ItemContainerWrapper>
      <ViewAll href="/shop/information_categories/topics">
        VIEW ALL
        <SvgSmallUpRightArrow />
      </ViewAll>
    </Inner>
  </Outer>
}