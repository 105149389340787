import React, { FunctionComponent, ReactNode } from 'react'
import styled, { keyframes } from 'styled-components'
import { isNotNullish } from '../utils/type-check'
import { Animate } from './animate'

const show = keyframes`
  from {
    opacity: 0;
    filter: blur(0.25rem);
  }
  to {
    opacity: 1;
    filter: none;
  }
`

const Character = styled.div`
  display: inline;
  opacity: 0;
  .animate > & {
    animation: 0.5s ${show} forwards;
  }
`

const nodeMap = (node: ReactNode, fn: (node: ReactNode, index: number, array: ReactNode[]) => ReactNode): ReactNode => {
  let index = 0
  const arr: ReactNode[] = []
  const reducer = (node: ReactNode): ReactNode => {
    if(isNotNullish(node)){
      if(typeof node === 'object' && Symbol.iterator in node || typeof node === 'string' && node.length > 1){
        return Array.from(node).map(reducer)
      }
    }
    index = index + 1
    arr.push(node)
  }
  reducer(node)
  return arr.map((node, i) => fn(node, i, arr))
}

type AnimatedTextProps = {
  children?: ReactNode
  delay?: number
}
export const AnimatedText: FunctionComponent<AnimatedTextProps> = ({children, delay}) => {
  return <Animate>
    {nodeMap(children, (node, i) => <Character key={i} style={{animationDelay: `${i * 0.05 + (delay ?? 0)}s`}}>{node}</Character>)}
  </Animate>
}