import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
`

type PageOuterProps = {
  children?: ReactNode
}
export const PageOuter: FunctionComponent<PageOuterProps> = ({children}) => {
  return <Outer>
    {children}
  </Outer>
}