import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import { Image } from '../components/image'
import { useSettings } from '../hooks/use-settings'
import SvgSmallUpRightArrow from '../generated/svg/small-up-right-arrow'
import { Animate } from '../components/animate'

const Outer = styled.div``

const Inner = styled.a`
  display: grid;
  grid-template-columns: 1fr calc(min(var(--page-max-width), 100vw) * 0.4) calc(min(var(--page-max-width), 100vw) * 0.6) 1fr;
  & + & {
    border-top: 1px solid var(--black);
    @media (max-width: 60rem){
      border-top: 0 none;
    }
  }
`

const LeftContainer = styled.div`
  background-color: var(--gold);
  color: var(--black);
  grid-column: span 2;
  display: grid;
  grid-template-columns: subgrid;
  position: relative;
  @media (max-width: 60rem){
    grid-column: span 4;
  }
`

const LeftInner = styled.div`
  padding-left: var(--page-margin);
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  grid-column: 2;
  @media (max-width: 60rem){
    grid-column: span 4;
    padding: 6rem var(--page-margin);
    height: auto;
  }
`

const RightContainer = styled.div`
  grid-column: span 2;
  @media (max-width: 60rem){
    grid-column: span 4;
  }
`

const Text1 = styled.div`
  font-size: 1rem;
  font-family: forum, serif;
  position: absolute;
  top: 3rem;
  left: 2.5rem;
  @media (max-width: 60rem){
    top: 1.5rem;
    left: 0;
    margin-left: var(--page-margin);
  }
  @media (max-width: 40rem){
    font-size: 0.813rem;
  }
`

const Text2 = styled.div`
  font-size: 4rem;
  font-family: forum, serif;
  line-height: 0.9;
  @media (max-width: 40rem){
    font-size: 2.750rem;
  }
`

const Text3 = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 2rem;
  @media (max-width: 40rem){
    font-size: 0.875rem;
  }
`

const Text4 = styled.div`
  font-size: 0.8125rem;
  line-height: 1.7;
  margin-top: 0.5rem;
  @media (max-width: 40rem){
    font-size: 0.688rem;
  }
`

const ImageContainer = styled.div`
  overflow: hidden;
  img {
    display: block;
    height: 56rem;
    width: 100%;
    object-fit: cover;
    transition: transform 0.8s;
    ${Inner}[href]:hover & {
      transform: scale(1.05);
    }
    @media (max-width: 60rem){
      height: auto;
      aspect-ratio: 4 / 3;
    }
  }
`

const slideIn = keyframes`
  from {
    transform: translate(-0.7071rem, 0.7071rem);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
`
const ViewMore = styled.div`
  font-family: forum, serif;
  font-size: 1.25rem;
  margin-top: 3rem;
  svg {
    height: 0.8rem;
    width: auto;
    margin-left: 1rem;
    .animate & {
      animation: ${slideIn} 1s 0.4s ease-in-out both;
    }
  }
  ${Inner}:hover & svg{
    animation: ${slideIn} 1s 0.4s ease-in-out both, ${slideIn} 0.4s ease-in-out both;
  }
`

type TopPageProductSection1Props = {

}
export const TopPageProductSection1: FunctionComponent<TopPageProductSection1Props> = () => {
  const {themeFileRootPath} = useSettings()
  return <Outer>
    <Inner>
      <LeftContainer>
        <LeftInner>
          <Text1>
            PRODUCT
          </Text1>
          <Text2>
            AUTHENTIC<br />
            FULL COURSE<br />
            THAT<br />
            GOES DEEP
          </Text2>
          <Text3>
            深く攻める本格派のフルコース
          </Text3>
        </LeftInner>
      </LeftContainer>
      <RightContainer>
        <ImageContainer>
          <Image src={`${themeFileRootPath}/assets/bundle/images/top_0010.jpg`} />
        </ImageContainer>
      </RightContainer>
    </Inner>
    <Inner href="/shop/products/KL-009-001">
      <LeftContainer>
        <LeftInner>
          <Text1>
            PRODUCT
          </Text1>
          <Text2>
            MULTI FACIAL<br />
            DEVICE<br />
            KALONEAR
          </Text2>
          <Text3>
            マルチ美顔器 カロニア
          </Text3>
          <Text4>
            13種の多彩なモードで頭皮から肌、身体に使えるマルチ美顔器。<br />
            悩みに深く攻め込むケアで、理想を超える美しさへ導きます。
          </Text4>
          <Animate>
            <ViewMore>
              VIEW MORE
              <SvgSmallUpRightArrow />
            </ViewMore>
          </Animate>
        </LeftInner>
      </LeftContainer>
      <RightContainer>
        <ImageContainer>
          <Image src={`${themeFileRootPath}/assets/bundle/images/top_0011.jpg`} />
        </ImageContainer>
      </RightContainer>
    </Inner>

  </Outer>
}