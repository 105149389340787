import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { Image } from '../components/image'
import { useSettings } from '../hooks/use-settings'
import { AnimatedText } from '../components/animated-text'
import { Autoplay, EffectFade } from 'swiper/modules'
import classNames from 'classnames'
import { useBreakpoint } from '../hooks/use-break-point'
import { Mbr } from '../components/br'

const Outer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`

const Inner = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  @media (max-width: 40rem){
    display: flex;
    flex-direction: column;
  }
`

const SwiperContainer = styled.div`
  @media (max-width: 40rem){
    flex: 1 1 0;
    min-height: 0;
    .swiper,
    .swiper-wrapper {
      height: 100%;
    }
  }
`

const SlideInner = styled.div`
  position: relative;
  @media (max-width: 40rem){
    height: 100%;
  }
`

const ImageContainer = styled.div`
  @media (max-width: 40rem){
    height: 100%;
  }
  img {
    display: block;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: 30% 50%;
    @media (max-width: 40rem){
      height: 100%;
      object-position: 50% 50%;
    }
  }
`

const TextContainer = styled.div`
  position: absolute;
  top: 40%;
  left: var(--page-margin);
`

const Text1 = styled.div`
  font-size: 4rem;
  line-height: 0.9;
  font-family: forum, serif;
  span {
    display: none;
    @media (max-width: 40rem){
      display: inline;
    }
  }
  @media (max-width: 40rem){
    font-size: 2.25rem;
  }
`

const Text2 = styled.div`
  font-size: 1rem;
  margin-top: 1.875rem;
  @media (max-width: 40rem){
    font-size: 0.6875rem;
  }
`


const Overlay = styled.div`
  position: absolute;
  right: 0;
  bottom: 3rem;
  z-index: 1;
  @media (max-width: 40rem){
    bottom: 0;
    width: 100%;
    position: static;
    bottom: auto;
  }
`

const Controls = styled.div`
  display: flex;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  bottom: 1rem;
  left: 50%;
  width: 50%;
  height: auto;
  flex-direction: row;
  text-align: left;
  top: auto;
  gap: 0.25rem;
  @media (max-width: 40rem){
    bottom: 10rem;
    right: var(--page-margin);
    transform: translateY(-50%);
  }
`

const Point = styled.div`
  cursor: pointer;
  background-color: #fff5;
  border-radius: 0;
  flex: 1 1 0;
  height: 0.125rem;
  width: auto;
  transition: background-color 0.4s;
  border: 0 none;
  box-sizing: content-box;
  &.active {
    border-color: transparent;
    background-color: #fff;
  }
`

const Banner = styled.a`
  display: block;
  img {
    height: 9rem;
    width: auto;
    display: block;
  }
`

type TopPageBannerSectionProps = {

}
export const TopPageBannerSection: FunctionComponent<TopPageBannerSectionProps> = () => {
  const {themeFileRootPath} = useSettings()
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [slideCount, setSlideCount] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [show, setShow] = useState(true)
  const isMobile = useBreakpoint('40rem')
  useEffect(() => {
    const listener = () => {
      if(window.scrollY > window.innerHeight){
        setShow(false)
      } else {
        setShow(true)
      }
    }
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  })
  return <Outer>
    <Inner style={{display: show ? undefined : 'none'}}>
      <SwiperContainer>
        <Swiper
          autoplay={{
            delay: 3000,
            pauseOnMouseEnter: true,
            disableOnInteraction: true
          }}
          effect="fade"
          loop
          speed={1000}
          modules={[
            EffectFade,
            Autoplay
          ]}
          onSwiper={swiper => setSwiper(swiper)}
          onSlidesUpdated={swiper => {
            setSlideCount(swiper.slides.length)
          }}
          onSlideChange={swiper => {
            setActiveIndex(swiper.realIndex)
          }}
        >
          {/* <SwiperSlide>
            <SlideInner>
              <ImageContainer>
                {
                  isMobile ?
                  <Image src={`${themeFileRootPath}/assets/bundle/images/top_0013m.jpg`} />:
                  <Image src={`${themeFileRootPath}/assets/bundle/images/top_0013.jpg`} />
                }
              </ImageContainer>
              <TextContainer style={{color: isMobile ? 'black' : 'black'}}>
                <Text1>
                  <AnimatedText>
                    MANIPU<span>-<br /></span>LATE<br />
                    BEAUTY<br />
                    AS YOU<Mbr /> WISH
                  </AnimatedText>
                </Text1>
                <Text2>
                  <AnimatedText delay={1.9}>
                    思い通りに、美を操る。
                  </AnimatedText>
                </Text2>
              </TextContainer>
            </SlideInner>
          </SwiperSlide> */}
          <SwiperSlide>
            <SlideInner>
              <ImageContainer>
                {
                  isMobile ?
                  <Image src={`${themeFileRootPath}/assets/bundle/images/top_0002m.jpg`} />:
                  <Image src={`${themeFileRootPath}/assets/bundle/images/top_0002.jpg`} />
                }
              </ImageContainer>
              <TextContainer style={{color: 'white'}}>
                <Text1>
                  <AnimatedText>
                    MANIPU<span>-<br /></span>LATE<br />
                    BEAUTY<br />
                    AS YOU<Mbr /> WISH
                  </AnimatedText>
                </Text1>
                <Text2>
                  <AnimatedText delay={1.9}>
                    思い通りに、美を操る。
                  </AnimatedText>
                </Text2>
              </TextContainer>
            </SlideInner>
          </SwiperSlide>
          <SwiperSlide>
            <SlideInner>
              <ImageContainer>
                {
                  isMobile ?
                  <Image src={`${themeFileRootPath}/assets/bundle/images/top_0003m.jpg`} />:
                  <Image src={`${themeFileRootPath}/assets/bundle/images/top_0003.jpg`} />
                }
              </ImageContainer>
              <TextContainer style={{color: 'white'}}>
                <Text1>
                  <AnimatedText>
                    MANIPU<span>-<br /></span>LATE<br />
                    BEAUTY<br />
                    AS YOU<Mbr /> WISH
                  </AnimatedText>
                </Text1>
                <Text2>
                  <AnimatedText delay={1.9}>
                    思い通りに、美を操る。
                  </AnimatedText>
                </Text2>
              </TextContainer>
            </SlideInner>
          </SwiperSlide>
          <SwiperSlide>
            <SlideInner>
              <ImageContainer>
                {
                  isMobile ?
                  <Image src={`${themeFileRootPath}/assets/bundle/images/top_0005m.jpg`} />:
                  <Image src={`${themeFileRootPath}/assets/bundle/images/top_0005.jpg`} />
                }
              </ImageContainer>
              <TextContainer style={{color: 'white'}}>
                <Text1>
                  <AnimatedText>
                    MANIPU<span>-<br /></span>LATE<br />
                    BEAUTY<br />
                    AS YOU<Mbr /> WISH
                  </AnimatedText>
                </Text1>
                <Text2>
                  <AnimatedText delay={1.9}>
                    思い通りに、美を操る。
                  </AnimatedText>
                </Text2>
              </TextContainer>
            </SlideInner>
          </SwiperSlide>
        </Swiper>
      </SwiperContainer>
      <Overlay>
        <Banner href="/shop/products/KL-009-001">
          <Image src={`${themeFileRootPath}/assets/bundle/images/banner_04.png`} />
        </Banner>
      </Overlay>
      <Controls>
        {Array.from(Array(slideCount)).map((_, i) => {
          return <Point key={i} className={classNames({active: i === activeIndex})} onClick={() => {
            if(swiper){
              swiper.slideTo(i)
            }
          }} />
        })}
      </Controls>
    </Inner>
  </Outer>
}