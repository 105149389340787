import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import { useSettings } from '../hooks/use-settings'
import { Image } from '../components/image'
import SvgLargeUpRightArrow from '../generated/svg/large-up-right-arrow'
import SvgSmallUpRightArrow from '../generated/svg/small-up-right-arrow'
import { Animate } from '../components/animate'

const Outer = styled.div`
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin: 8rem 0;
  position: relative;
`

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0;
  margin: 0 auto;
  @media (max-width: 60rem){
    grid-template-columns: 1fr;
    padding: 1rem var(--page-margin);
  }
`

const HeadContainer = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  @media (max-width: 60rem){
    padding: 0;
  }
`

const Head = styled.div`
  font-size: 3rem;
  font-family: forum, serif;
  position: absolute;
  left: var(--page-margin);
  @media (max-width: 60rem){
    margin-bottom: 3rem;
    position: static;
    left: var(--page-margin);
  }
  @media (max-width: 40rem){
    font-size: 2.25rem;
  }
`

const slideIn = keyframes`
  from {
    transform: translate(-0.7071rem, 0.7071rem);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
`

const ViewMore = styled.a`
  font-family: forum, serif;
  font-size: 1.25rem;
  letter-spacing: 0.03em;
  margin-top: auto;
  display: block;
  position: absolute;
  left: var(--page-margin);
  bottom: 2rem;
  @media (max-width: 60rem){
    display: none;
    left: var(--page-margin);
  }
  svg {
    height: 0.75em;
    width: auto;
    margin-left: 1rem;
    .animate & {
      animation: ${slideIn} 1s 0.4s ease-in-out both;
    }
  }
  &:hover svg{
    animation: ${slideIn} 1s 0.4s ease-in-out both, ${slideIn} 0.4s ease-in-out both;
  }
`

const Item = styled.a`
  display: block;
  background-color: var(--gold);
  color: var(--black);
  position: relative;
  padding: 2.5rem;
  @media (max-width: 60rem){
    padding: var(--page-margin);
    max-width: 25rem;
    justify-self: center;
  }
`

const Text1 = styled.div`
  font-size: 0.8125rem;
  @media (max-width: 40rem){
    font-size: 0.688rem;
  }
`

const Title = styled.div`
  font-size: 2.250rem;
  font-family: forum, serif;
  margin-top: 1rem;
  @media (max-width: 40rem){
    font-size: 1.750rem;
  }
`

const TitleJa = styled.div`
  font-size: 1rem;
  margin-top: 0.5rem;
  @media (max-width: 40rem){
    font-size: 0.875rem;
  }
`

const Text2 = styled.div`
  font-size: 0.8125rem;
  line-height: 1.7;
  margin-top: 3rem;
  margin-right: 3rem;
  @media (max-width: 40rem){
    font-size: 0.688rem;
  }
`

const ImageContainer = styled.div`
  margin-top: 3rem;
  img {
    display: block;
    height: 22.875rem;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 40rem){
      height: 17.875rem;
    }
  }
`

const ArrowContainer = styled.div`
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  @media (max-width: 40rem){
    right: 1.5rem;
    bottom: 2rem;
  }
  svg {
    display: block;
    height: 1.25rem;
    width: auto;
    .animate & {
      animation: ${slideIn} 1s 0.4s ease-in-out both;
    }
  }
  ${Item}:hover & svg{
    animation: ${slideIn} 1s 0.4s ease-in-out both, ${slideIn} 0.4s ease-in-out both;
  }
`


type TopPageProductListSectionProps = {

}
export const TopPageProductListSection: FunctionComponent<TopPageProductListSectionProps> = () => {
  const {themeFileRootPath} = useSettings()
  return <Outer>
    <Inner>
      <HeadContainer>
        <Head>
          PRODUCT<br />
          LIST
        </Head>
        <Animate>
          <ViewMore href="/shop/products">
            VIEW MORE
            <SvgSmallUpRightArrow />
          </ViewMore>
        </Animate>
      </HeadContainer>
      <Item href="/shop/products/KL-009-001">
        <Text1>
          マルチ美顔器
        </Text1>
        <Title>
          KALONEAR
        </Title>
        <TitleJa>
          カロニア
        </TitleJa>
        <ImageContainer>
          <Image src={`${themeFileRootPath}/assets/bundle/images/product_0001.jpg`} alt="マルチ美顔器 KALONEAR"/>
        </ImageContainer>
        <Text2>
          頭皮、肌、身体とこれひとつで全身ケア。<br />
          業務用美容メーカーが開発した多機能マルチ美顔器。
        </Text2>
        <Animate>
          <ArrowContainer>
            <SvgLargeUpRightArrow />
          </ArrowContainer>
        </Animate>
      </Item>
    </Inner>
  </Outer>
}