import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'

const Outer = styled.div``

const scroll = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-33.333%);
  }
`
const Inner = styled.div`
  display: flex;
  animation: ${scroll} 20s linear infinite;
  width: max-content;
`


const Text = styled.div`
  font-family: forum, serif;
  font-size: 11.25rem;
  width: max-content;
  white-space: nowrap;
  padding-right: 0.5em;
  letter-spacing: 0.03em;
  @media (max-width: 40rem){
    font-size: 5rem;
  }
`

type TopPageProductSection3Props = {

}
export const TopPageProductSection3: FunctionComponent<TopPageProductSection3Props> = () => {
  return <Outer>
    <Inner>
      <Text>ESSENCE OF BEAUTY.</Text>
      <Text>ESSENCE OF BEAUTY.</Text>
      <Text>ESSENCE OF BEAUTY.</Text>
    </Inner>
  </Outer>
}