import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgGraph01 from '../generated/svg/graph01'
import { Animate } from '../components/animate'

const Outer = styled.div`
  max-width: var(--page-max-width);
  padding: 8rem var(--page-margin);
  margin: 0 auto;
  @media (max-width: 40rem){
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`

const Inner = styled.div`
  display: flex;
  gap: 2rem;
  @media (max-width: 50rem){
    flex-direction: column;
    gap: 3rem;
  }
`

const TextContainer = styled.div`
  margin-top: 4rem;
  @media (max-width: 50rem){
    margin-top: 0;
  }
`

const Text1 = styled.div`
  font-family: forum, serif;
  font-size: 3rem;
  white-space: nowrap;
  @media (max-width: 40rem){
    font-size: 2.25rem;
  }
`

const Text2 = styled.div`
  font-size: 1rem;
  margin-top: 3rem;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    margin-top: 2rem;
  }
`

const Text3 = styled.div`
  font-size: 0.8125rem;
  line-height: 2;
  margin-top: 1.5rem;
  @media (max-width: 40rem){
    font-size: 0.688rem;
    margin-top: 0.75rem;
  }
`

const GraphContainer = styled.div`
  margin: 0 auto;
  svg {
    display: block;
    width: 40rem;
    max-width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 2s;
    * {
      backface-visibility: hidden;
      will-change: transform;
    }
    @media (max-width: 40rem){
      margin-top: 2rem;
    }
  }
  .animate  svg {
    opacity: 1;
  }
`

type TopPageGraphSectionProps = {

}
export const TopPageGraphSection: FunctionComponent<TopPageGraphSectionProps> = () => {
  return <Outer>
    <Inner>
      <TextContainer>
        <Text1>
          A DEEPER,<br />
          INSATIABLE<br />
          INQUIRING MIND
        </Text1>
        <Text2>
          さらに深く、飽くなき探求心。
        </Text2>
        <Text3>
          肌表面を整えるスキンケアでは叶わない、<br />
          さらに奥の脂肪、筋肉にも刺激を与えるアプローチ。<br />
          根源からケアすることで内側から湧き出るような、<br />
          強く確かな美しさが宿ります。
        </Text3>
      </TextContainer>
      <GraphContainer>
        <Animate>
          <SvgGraph01 />
        </Animate>
      </GraphContainer>
    </Inner>
  </Outer>
}