import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { PageOuter } from '../components/page-outer'
import { TopPageBannerSection } from '../sections/top-page-banner-section'
import { TopPageGraphSection } from '../sections/top-page-graph-section'
import { TopPageTopicsSection } from '../sections/top-page-topics-section'
import { TopPageProductListSection } from '../sections/top-page-product-list-section'
import { TopPageInstagramSection } from '../sections/top-page-instagram-section'
import { TopPageBottomSection } from '../sections/top-page-bottom-section'
import { TopPageProductSection1 } from '../sections/top-page-product-section1'
import { TopPageProductSection2 } from '../sections/top-page-product-section2'
import { VerticalSpacer } from '../components/specer'
import { TopPageProductSection3 } from '../sections/top-page-product-section3'

const Outer = styled.div`
  
`

const Inner = styled.div`
  position: relative;
  z-index: 1;
  background-color: var(--black);
  color: #fff;
`

type TopPageProps = {

}
export const TopPage: FunctionComponent<TopPageProps> = () => {
  return <Outer>
    <PageOuter>
      <TopPageBannerSection />
      <Inner>
        <TopPageGraphSection />
        <TopPageTopicsSection />
        <VerticalSpacer length="12rem" mLength='2rem' />
        <TopPageProductSection1 />
        <TopPageProductSection2 />
        <TopPageProductSection3 />
        <TopPageProductListSection />
        <TopPageInstagramSection />
        <TopPageBottomSection />
      </Inner>
    </PageOuter>
  </Outer>
}