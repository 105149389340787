import * as React from "react";
import type { SVGProps } from "react";
const SvgLargeUpRightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.499 20.477"
    {...props}
  >
    <path d="M19.999 15.526V.5H4.988" fill="none" stroke="currentcolor" />
    <path
      d="M19.595.864.354 20.124"
      fill="none"
      stroke="currentcolor"
      strokeMiterlimit={4}
      strokeWidth="1px"
    />
  </svg>
);
export default SvgLargeUpRightArrow;
