import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import { Image } from '../components/image'
import { useSettings } from '../hooks/use-settings'
import { Animate } from '../components/animate'
import { Mbr } from '../components/br'

const Outer = styled.div`
  max-width: var(--page-max-width);
  padding: 8rem var(--page-margin);
  margin: 0 auto;
  @media (max-width: 50rem){
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`

const Inner = styled.div`

`

const Item = styled.div`
  display: flex;
  gap: 2.5rem;
  @media (max-width: 50rem){
    flex-direction: column;
    @media (max-width: 40rem){
      gap: var(--page-margin);
    }
  }
  & + & {
    margin-top: 6.25rem;
  }
`

const ImageContainer = styled.div`
  flex: 0 0 60%;
  @media (max-width: 50rem){
    order: -1;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
    @media (max-width: 50rem){
      width: calc(100% + var(--page-margin));
      ${Item}:nth-child(1) & {
        margin-left: calc(var(--page-margin) * -1);
      }
      ${Item}:nth-child(2) & {
        margin-right: calc(var(--page-margin) * -1);
      }
    }
  }
`

const scale = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: none;
  }
`

const TextContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 2.5rem 0;
  position: relative;
  &::before, &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: white;
    position: absolute;
    left: 0;
    transform-origin: 0 0;
    transform: scaleX(0);
    .animate & {
      animation: ${scale} 1.2s 0.5s ease-in-out both;
    }
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
    animation-delay: 1s !important;
  }
  @media (max-width: 50rem){
    padding: var(--page-margin) 0;
    width: calc(100% + var(--page-margin));
    ${Item}:nth-child(1) & {
      margin-right: calc(var(--page-margin) * -1);
    }
    ${Item}:nth-child(2) & {
      margin-left: calc(var(--page-margin) * -1);
      padding-left: var(--page-margin);
      box-sizing: border-box;
    }
  }
`

const Text1 = styled.div`
  font-size: 2.625rem;
  line-height: 0.96;
  font-family: forum, serif;
  margin-bottom: 2rem;
  @media (max-width: 40rem){
    font-size: 2rem;
    margin-bottom: 0;
  }
`

const Text2 = styled.div`
  font-size: 1rem;
  margin-top: auto;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    margin-top: 4.375rem;
  }
`

const Text3 = styled.div`
  font-size: 0.8125rem;
  line-height: 1.7;
  margin-top: 1.5rem;
  @media (max-width: 40rem){
    font-size: 0.688rem;
    margin-top: 1rem;
  }
`

type TopPageProductSection2Props = {

}
export const TopPageProductSection2: FunctionComponent<TopPageProductSection2Props> = () => {
  const {themeFileRootPath} = useSettings()
  return <Outer>
    <Inner>
      <Item>
        <ImageContainer>
          <Image src={`${themeFileRootPath}/assets/bundle/images/top_0006.jpg`} />
        </ImageContainer>
        <Animate>
          <TextContainer>
            <Text1>
              SIMPLE<br />
              SUGGESTIONS,<br />
              CLEAR<br />
              ANSWERS
            </Text1>
            <Text2>
              シンプルな提案、明確な答え。
            </Text2>
            <Text3>
              将来を見据えた、その場限りで終わらない美への追求。<br />
              継続には見極める力、選び抜く力も必要です。<br />
              その判断材料として、何が提案できるのか。<br />
              私たちは「美に求めるのは実感と結果」だと考えます。
            </Text3>
          </TextContainer>
        </Animate>
      </Item>
      <Item>
        <Animate>
          <TextContainer>
            <Text1>
              CRYSTALS<br />
              PILED UP<br />
              AND SHAPED
            </Text1>
            <Text2>
              積み重なり、形となった「結晶」
            </Text2>
            <Text3>
              業務用美容メーカーで培ってきたすべてを<Mbr />惜しみなく注いだマルチ美顔器。<br />
              特許取得技術や3種同時出力のモードを搭載し、<br />
              私たちだからこそ実現できた技術を全身で体感できます。
            </Text3>
          </TextContainer>
        </Animate>
        <ImageContainer>
          <Image src={`${themeFileRootPath}/assets/bundle/images/top_0007.jpg`} />
        </ImageContainer>
      </Item>
    </Inner>
  </Outer>
}