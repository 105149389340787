import * as React from "react";
import type { SVGProps } from "react";
const SvgSmallUpRightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10.196 10.096"
    {...props}
  >
    <path
      d="M9.696 7.672V.5H2.524M9.546.55.354 9.743"
      fill="none"
      stroke="currentcolor"
    />
  </svg>
);
export default SvgSmallUpRightArrow;
